import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

const AltTooltip = ({ bodyContent, children }) => {
  return (
    <OverlayTrigger
      placement='right'
      overlay={
        <Popover
          style={{
            padding: '10px',
            color: '#373a36',
            borderRadius: 8,
            borderColor: 'transparent',
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)'
          }}
        >
          <div className='d-flex flex-column align-items-center'>
            <p>{bodyContent}</p>
          </div>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  )
}

export default AltTooltip
