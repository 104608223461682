import React, { Fragment, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import ProgressBar from "./CustomProgressBar";
import Stepper from "./Stepper";
import { isEmpty, trim } from "lodash";
import { validateEmail } from "../utils";

const YourInfo = ({ contentful }) => {
  const { search, setSearch, setView, touched, setTouched } = useContext(
    TrademarkSearchContext
  );

  const {
    progressBar: { prescreen, prescreenTitle },
    yourInfo: { title, subtitle },
  } = contentful;

  /**
   * The EmailValidator library only checks the structure/format of the email
   */
  const validateFields = () => {
    if (!validateEmail(search.email) || isEmpty(trim(search.fullName))) {
      setTouched({
        ...touched,
        fullName: isEmpty(trim(search.fullName)),
        email: !validateEmail(trim(search.email)),
      });
    } else {
      setView("trademark-type");
    }
  };

  return (
    <>
      <Container className="pt-5">
        <Row className="text-center pb-3">
          <Col md={12}>
            <h1>{title}</h1>
            <ProgressBar
              labels={prescreen}
              activeStep={1}
              title={prescreenTitle}
            />
            <p>{subtitle}</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="py-3">
          <Col lg={{ offset: 3, span: 6 }}>
            {[
              {
                id: "fullName",
                label: "Full name",
                placeholder: "John Smith",
              },
              {
                id: "email",
                label: "Email address",
                placeholder: "john@gmail.com",
              },
            ].map((input, index) => (
              <Fragment key={index}>
                <Form.Group controlId={input.id}>
                  <Form.Label>{input.label}</Form.Label>
                  <Form.Control
                    className={
                      touched[input.id] ? "input input-error" : "input"
                    }
                    onBlur={(e) => {
                      e.target.value === "" &&
                        setTouched({ ...touched, [input.id]: true });
                    }}
                    onChange={(e) => {
                      setSearch({ ...search, [input.id]: e.target.value });
                      setTouched({ ...touched, [input.id]: false });
                    }}
                    value={search[input.id] || ""}
                    type="text"
                    size="lg"
                    placeholder={input.placeholder}
                    aria-label={input.label}
                  />
                </Form.Group>
              </Fragment>
            ))}
          </Col>
        </Row>
      </Container>

      <Container>
        <Stepper
          showNext
          nextText="Continue"
          showPrev={false}
          onNext={() => validateFields()}
        />
      </Container>
    </>
  );
};

export default YourInfo;
