import React from "react";
import { Button, Col, Container, Modal, Row, Image } from "react-bootstrap";
import Haloo from "../assets/icons/powered-by-haloo.png";

const HalooModal = ({
  content,
  showModal,
  setShowModal,
  type,
  trademarkId,
  jurisdiction,
}) => {
  return (
    <Modal
      className="haloo prescreening"
      dialogClassName={type}
      show={showModal}
      centered
      backdrop="static"
      size="lg"
    >
      <Modal.Body className="pt-5 text-center">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <h1>{content.title}</h1>
            </Col>
            <Col md={12} className="py-4">
              <u>
                <p>{content.desc1}</p>
              </u>
              <p className="pt-4">{content.desc2[`${jurisdiction}`]}</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={7}>
              <div className="btn btn-primary d-flex justify-content-center align-items-center">
                <a
                  href={`${process.env.REACT_APP_HALOO_URL}/${
                    jurisdiction.toLowerCase() === "ca" ? "en-ca/" : ""
                  }get-started?queryId=${trademarkId}`}
                  className="text-white font-sanserif no-underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Continue with full report anyway
                </a>
              </div>
              <Image height="29" src={Haloo} alt="Powered by Haloo" />
            </Col>
            <Col md={12}>
              <Button variant="link" onClick={() => setShowModal(false)}>
                Back
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default HalooModal;
