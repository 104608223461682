import React, { useContext, useEffect } from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import Tooltip from "./Tooltip";
import nikeLogo from "../assets/icons/nike.jpeg";

const Overview = ({ setToggle, contentful }) => {
  const { search, setSearch, touched, setTouched } = useContext(
    TrademarkSearchContext
  );

  useEffect(() => {
    setTouched({
      ...touched,
      businessCategories: false,
      trademark: false,
      trademarkLogoSrc: false,
    });
    setSearch({
      ...search,
      trademark: "",
      trademarkLogo: [],
      businessCategories: [],
    });
  }, []);

  return (
    <Row className="pb-md-5 pb-3">
      {contentful.map((card, index) => (
        <Col key={`${index}-${card.title}`} md={4} className="px-0">
          <Row
            onClick={() => {
              setToggle(card.title.toLowerCase());
              setSearch({
                ...search,
                type: card.title.toLowerCase(),
              });
            }}
            className="prescreening-card"
          >
            <Col md={12}>
              <h2>{card.title}</h2>
            </Col>
            <Col md={12} className="pb-2 pb-md-0">
              <p>{card.description}</p>
            </Col>
            <Col md={12} className="align-self-end">
              <Button
                variant="outline"
                className="w-100"
              >{`${card.title} trademark`}</Button>
            </Col>
          </Row>
          <div className="text-left text-md-center">
            <Tooltip
              bodyContent={
                card.title.toLowerCase() === "logo" ? (
                  <>
                    <Image fluid src={nikeLogo} alt="Nike" width={80} />
                    <small className="d-block">{card.tooltip.content}</small>
                  </>
                ) : (
                  <small>{card.tooltip.content}</small>
                )
              }
            >
              <a className="tooltip-hover underline">{card.tooltip.label}</a>
            </Tooltip>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Overview;
