import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Haloo from "../assets/icons/powered-by-haloo.png";
import check from "../assets/icons/circle-check.svg";
import { Link } from "react-router-dom";

const content = {
  sectionOne: {
    title: "Trademarking made simple with Partner & Haloo",
    subtitle:
      "Verify your trademark using our quick, easy, and free search tool.",
  },
  ctaText: "Run my free search",
  sectionTwo: {
    title: "Set your business up for success",
    subtitle:
      "Companies that register trademarks perform better and earn more revenue. Trademarks allow you to:",
    list: [
      "Legally own your domain and social handles",
      "Sell on Amazon with the benefits of Brand Registry",
      "Stop competitors from using your name or logo",
      "Sell through retailers and distributors (proof of trademark required)",
    ],
  },
  sectionThree: {
    title: "Three easy steps",
    subtitle:
      "In less than 1 minute our tool will show you if your trademark is legally clear, if your domain is eligible for registration, and if your social handles are available.",
    progressBar: {
      title: "Trademark Check",
      labels: ["Your information", "Trademark type", "Business information"],
    },
  },
  sectionFour: {
    title: "Ready to run a check?",
    subtitle:
      "Partner & Haloo trademark and domain search is just as thorough and reliable as a lawyer search. But it's free and easy to use.",
  },
};

const PartnerIntegrationView = () => {
  return (
    <div className="partner-integration">
      <Container fluid className="bg-primary-light">
        <Container>
          <Row className="justify-content-md-center text-center">
            <Col md={12} lg={10}>
              <h1>{content.sectionOne.title}</h1>
            </Col>
            <Col md={10} className="pt-3 pb-5">
              <p style={{ fontSize: "20px" }}>{content.sectionOne.subtitle}</p>
            </Col>
            <Col md={4} lg={3}>
              <Link to="/get-started">
                <Button variant="primary" className="w-100">
                  {content.ctaText}
                </Button>
              </Link>
            </Col>
            <Col md={12} className="pt-1">
              <Image height="43" src={Haloo} alt="Powered by Haloo" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={10}>
              <h1>{content.sectionTwo.title}</h1>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md={9} className="py-3">
              <p>{content.sectionTwo.subtitle}</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} xl={8}>
              <Row xs={1} md={2}>
                {content.sectionTwo.list.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      className="d-flex flex-md-row align-items-center py-2"
                    >
                      <Image height="38" src={check} />
                      <p className="font-serif ms-3">{item}</p>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="bg-grey-light">
        <Container>
          <Row className="justify-content-md-center text-center">
            <Col md={10}>
              <h1>{content.sectionThree.title}</h1>
            </Col>
            <Col md={8} className="py-3">
              <p>{content.sectionThree.subtitle}</p>
            </Col>
          </Row>
          <Row className="justify-content-center text-center pt-md-4">
            <Col md={12}>
              <p>{content.sectionThree.progressBar.title}</p>
            </Col>
            <Col xs={12} md={9} className="pt-2">
              <ProgressBar now={30} />
            </Col>
          </Row>
          <Row className="progress-bar-labels justify-content-center text-center position-relative">
            {content.sectionThree.progressBar.labels.map((step, index) => {
              return (
                <Col key={`${index}-${step}`} xs={3} sm={3}>
                  <span>|</span>
                  <p className="index">{index + 1}</p>
                  <p className="label px-2">{step}</p>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <Container>
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={12}>
              <h1>{content.sectionFour.title}</h1>
            </Col>
            <Col md={9} className="pt-3 pb-5">
              <p>{content.sectionFour.subtitle}</p>
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <Col md={4} lg={3}>
              <Link to="/get-started">
                <Button variant="primary" className="w-100">
                  {content.ctaText}
                </Button>
              </Link>
            </Col>
            <Col md={12} className="pt-1">
              <Image height="43" src={Haloo} alt="Powered by Haloo" />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default PartnerIntegrationView;
