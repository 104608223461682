import React, { useContext, useState } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import check from "../assets/icons/check.svg";
import cross from "../assets/icons/cross.svg";
import SocialCard from "./SocialDomainResults";
import Haloo from "../assets/icons/powered-by-haloo.png";
import HalooModal from "./HalooModal";
import { Link } from "react-router-dom";
import { OVERALLRISKSCORES } from "../constants";

const PrescreenTrademarkResults = ({ contentful }) => {
  const { results } = useContext(TrademarkSearchContext);

  const [showModal, setShowModal] = useState(false);

  const {
    prescreenResults: {
      subtitle,
      result,
      resultDesc,
      resultDesc2,
      callToAction,
    },
  } = contentful;

  const { trademark, overallRisk, trademarkId, country } = results;

  const overallRiskLowerCase =
    OVERALLRISKSCORES[`${overallRisk.toLowerCase()}`];
  const cta = () => {
    return (
      <Container>
        <Row className="justify-content-center pb-4">
          <Col md={7}>
            <p className="text-center">
              {overallRiskLowerCase === "high"
                ? `${callToAction.highRiskMessage}`
                : `${callToAction.lowRiskMessage}`}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {overallRiskLowerCase === "high" ? (
            <>
              <Col xs={11} md={3} className="text-center">
                <Link className="" to="/get-started">
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={() => setShowModal(true)}
                  >
                    {callToAction.highRiskbuttonText[0]}
                  </Button>
                </Link>
              </Col>
              <Col xs={11} md={3} className="pt-3 pt-md-0 text-center">
                <Button
                  variant="outline"
                  className="w-100"
                  onClick={() => setShowModal(true)}
                >
                  {callToAction.highRiskbuttonText[1]}
                </Button>
              </Col>
            </>
          ) : (
            <Col xs={11} md={4} className="text-center">
              <a
                href={`${process.env.REACT_APP_HALOO_URL}/${
                  country.toLowerCase() === "ca" ? "en-ca/" : ""
                }get-started?queryId=${trademarkId}`}
                className="text-white font-sanserif no-underline"
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  variant="primary"
                  className="w-100 d-flex justify-content-center align-items-center"
                >
                  {callToAction.lowRiskbuttonText[0]}
                </Button>
              </a>
            </Col>
          )}
        </Row>
        <Row className="justify-content-center pt-3">
          <Col xs={11} md={4} className="text-center">
            <Image height="29" src={Haloo} alt="Powered by Haloo" />
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <HalooModal
        onNext
        showModal={showModal}
        setShowModal={setShowModal}
        type=""
        trademarkId={trademarkId}
        content={contentful.prescreenResults.failModal}
        jurisdiction={country.toLowerCase()}
      />
      <Container
        fluid
        className={`${
          overallRiskLowerCase === "high"
            ? "bg-secondary-light text-center"
            : "bg-primary-light text-center"
        }`}
      >
        <Container>
          <>
            <p>{subtitle}</p>
            <img
              className="p-3"
              alt=""
              src={overallRiskLowerCase === "high" ? cross : check}
            />
          </>

          <h1 className="mb-5">
            {result[overallRiskLowerCase === "high" ? "fail" : "pass"]}
          </h1>
          <Row>
            <Col lg={{ offset: 3, span: 6 }}>
              <p className="font-sanserif pb-3">
                It looks like your{" "}
                {results.type === "logo" ? (
                  "logo"
                ) : (
                  <u>
                    <strong>{trademark?.trademark?.toUpperCase()}</strong>
                  </u>
                )}{" "}
                {overallRiskLowerCase === "high"
                  ? resultDesc["fail"]
                  : resultDesc["pass"][results.type]}
              </p>
              <small>
                {overallRiskLowerCase === "high"
                  ? `${resultDesc2.fail}`
                  : `${resultDesc2.pass[`${country.toLowerCase()}`]}`}
              </small>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </Container>
      {results.type !== "logo" && cta()}
      {results.type === "logo" && (
        <Container className="text-center py-3">
          <img src={results.logo.imagePath} alt="your logo" height="100px" />
        </Container>
      )}
      {results.type !== "logo" && <SocialCard results={results} />}
      {cta()}
    </>
  );
};

export default PrescreenTrademarkResults;
