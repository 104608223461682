import axios from "axios";
const Agent = require("agentkeepalive");

const keepAliveAgent = new Agent({
  keepAlive: true,
  // maxSockets: 100,
  // maxFreeSockets: 10,
  // timeout: 60000, // active socket keepalive for 60 seconds
  // freeSocketTimeout: 30000 // free socket keepalive for 30 seconds
});

export default axios.create({
  baseURL: `${process.env.REACT_APP_HALOO_API_URL}/api/v2/partner`,
  httpAgent: keepAliveAgent,
  httspAgent: keepAliveAgent,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.REACT_APP_PARTNER_TOKEN}`,
  },
});
