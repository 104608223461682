import React, { useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { TrademarkSearchContext } from "../context/TrademarkSearchContextProvider";
import ProgressBar from "./CustomProgressBar";

const contentful = {
  time: "58 seconds.",
  error: {
    timeError: {
      title: "Stay tuned",
      description:
        "Your search is taking longer than expected. Sometimes this happens for very complex searches. We'd rather not keep you waiting so we'll send you an email when your results are in!",
    },
    queryError: {
      title: "Oops!",
      description:
        "We're sorry but we were unable to find your search results. We recently made significant upgrades to our systems and recommend that you run your search again. Free search limits have been reset.",
    },
    clientError: {
      title: "Uh-oh",
      description:
        "Your search couldn't complete. It's ok! We've recently made some upgrades to our tool, and all you need to do is refresh and run your search again.",
    },
    backendError: {
      title: "Oops!",
      description:
        "Our systems have encountered an issue that can't be resolved immediately. Rest assured that we're still running your search and will send you an email when your results are in.",
    },
  },
  steps: [
    "Variant spellings",
    "Phonetic equivalents",
    "Semantic variations",
    "Identical trademarks",
    "Translational variations",
  ],
  title: "This would take a lawyer a week,",
  title2: " we’re going to do it in",
};

const Loading = () => {
  const { results, setLoading, searchError } = useContext(
    TrademarkSearchContext
  );

  const { error, steps, time, title, title2 } = contentful;

  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    if (results) {
      setLoading(false);
    }
  }, [results]);

  return (
    <Container className="pb-5">
      {searchError ? (
        <div className="my-5">
          <h1>{error[searchError].title}</h1>
          <p>{error[searchError].description}</p>
        </div>
      ) : (
        <div className="mb-5">
          <h1 className="text-center py-4 mt-5">
            <p>{title} </p>
            <p className="font-serif">
              {title2} <u>{time}</u>
            </p>
          </h1>
          <ProgressBar animate labels={steps} />
        </div>
      )}
    </Container>
  );
};

export default Loading;
